import React, { useContext, useEffect, useRef, useState } from 'react';
import EditableTable from './EditableTable'

const Aircrafts = () => {
    return (
        <div>
            <EditableTable
                tableTitle='ВС'
                enableEdit={true}
                enableAddButton={true}
                linkGetColumns="Aircrafts/GetAircraftColumns"
                linkGet="Aircrafts/GetAircrafts"
                linkCreate='Aircrafts/CreateAircraft'
                linkDelete="Aircrafts/DeleteAircraft"
                linkUpdate="Aircrafts/UpdateAircraft"
                linkGetCreationFields='Aircrafts/GetAircraftFormEdits'
                enableDelete={true}
                modalTitle="Добавление ВС"
            />
        </div>
    );
};

export default Aircrafts;