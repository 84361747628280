import { MenuFoldOutlined, MenuUnfoldOutlined, UploadOutlined, UserOutlined, VideoCameraOutlined, PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Form, Select, Button, Input, DatePicker, TimePicker, InputNumber, Space,notification } from 'antd';
import FormFieldInput from './FormFieldInput'
import apiClient from '../api/apiClient'

const CreationForm = ({ onOk, onCancel, linkToPost, linkGetFields }) => {
    const loadOnce = useRef(true);
    const [formFields, SetFormFields] = useState([]);
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();
    
    const openNotification = (placement,errorText) => {
      api.info({
        message: `Возникла ошибка при сохранении`,
        description:{errorText},
        placement,
      });
    };

    const onFinish = async (values) => {
        try {
            let hidden = formFields.filter((field) => field.enableShow === false)
            hidden.map((field) => {
                let initValue = null;
                if (field.inputType === "dropdownMultiple") {
                    initValue = []
                }
                else if (field.inputType === "guid") {
                    initValue = "00000000-0000-0000-0000-000000000000"
                }
                else if (field.inputType === "int") {
                    initValue = 0
                }
                else if (field.inputType === "string") {
                    initValue = ''
                }
                else if (field.inputType === "date") {
                    initValue = null
                }
                values[field.name] = initValue

            })
            const res = await apiClient.post(linkToPost, values,)//to post values: {headers: {'Content-Type': 'multipart/form-data' },params: {values}}
            if(res.data.errors&&res.data.errors.lenght){
                res.data.errors.map((message)=>openNotification('top',message))
                console.log(res.data.errors)
            }
            else{
                onOk()
            }
        } catch (e) {
            openNotification('top',e)
            console.log(e)
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const SetFields = async () => {
        const res = await apiClient.get(linkGetFields)
        const cols = res.data.map((row) => {
            return row;
        });
        SetFormFields(cols);
    };

    useEffect(() => {
        if (loadOnce.current === true) {
            loadOnce.current = false
            SetFields()
            console.log("spam SetFields")
        }
    }, [])

    const FormItems = formFields.map((field) => {
        return (
            <FormFieldInput field={field} />
        )
    })

    return (
        <>
        {contextHolder}
        <Form
            name="basic"
            labelCol={{
                span: 8,
            }}
            wrapperCol={{
                span: 16,
            }}
            style={{
                maxWidth: 600,
            }}
            initialValues={{
                remember: true,
            }}
            size='small'
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"

        >
            {FormItems}
            <Form.Item
                key='buttons'
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
            >
                <Button onClick={onCancel}>
                    Отмена
                </Button>
                <Button type="primary" htmlType="submit" >
                    Сохранить
                </Button>
            </Form.Item>
        </Form>
        </>

    )
}


export default CreationForm;