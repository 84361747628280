import { createContext,useState,useEffect } from "react";

const AuthContext=createContext({});
const setLocalStorage=(key, value)=> {
    try {
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
    }
  }
  
  const getLocalStorage=(key)=> {
    try {
      const value = window.localStorage.getItem(key);
      return value ? JSON.parse(value) : {};
    } catch (e) {
      return {};
    }
  }
export const AuthProvider = ({children})=>{
    const [auth,setAuth]=useState(()=>getLocalStorage("auth"));

    useEffect(() => {
      setLocalStorage("auth", auth);
    }, [auth]);
  

    return (
        <AuthContext.Provider value={{auth,setAuth}}>
        {
            children
        }
        </AuthContext.Provider>
    )
}
export default AuthContext;