import {Switch} from 'antd';
import moment from 'moment'
const FormFieldDisplay=({field,fieldValue})=>{
    switch (field.inputType) {
        case 'string': {
            return (fieldValue)
        }
        case 'password': {
            return ("******")
        }
        case 'date': {
            return (
                moment(fieldValue).format("DD.MM.YYYY")
            )
        }
        case 'time': {
            return (
                moment(fieldValue).format("HH:mm")
            )
        }
        case 'dateTime': {
            return (
                moment(fieldValue).format("DD.MM.YYYY HH:mm")
            )
        }
        
        case 'bool': {
            return (
                    <Switch defaultChecked={fieldValue} disabled />
            )
        }
        default: {
                return (fieldValue)
        }
    }
}
export default FormFieldDisplay;