import React from 'react';
import EditableTable from './EditableTable'

const FlightSquads = () => {
  return (
    <div>
      <EditableTable
        tableTitle='Пользователи'
        enableEdit={true}
        enableAddButton={true}
        linkGetColumns="Pilots/GetFlightSquadsColumns"
        linkGet="Pilots/GetFlightSquads"
        linkCreate='Pilots/CreateFlightSquad'
        linkDelete="Pilots/DeleteFlightSquad"
        linkUpdate="Pilots/UpdateFlightSquad"
        linkGetCreationFields='Pilots/GetFlightSquadsFormEdits'
        enableDelete={true}
        modalTitle="Добавление пользователя"
      />
    </div>
  );
};

export default FlightSquads;