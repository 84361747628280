import { MenuFoldOutlined, MenuUnfoldOutlined, UploadOutlined, UserOutlined, VideoCameraOutlined, PlusOutlined, MinusCircleOutlined, DownOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Form, Input, Popconfirm, Table, Modal, Tag, Space, Layout, Card, Drawer,Switch,Descriptions, Divider,Tabs   } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import CreationForm from './CreationForm';
import apiClient from '../api/apiClient'
import moment from 'moment'
import FormFieldInput from './FormFieldInput';
import EditableTable from './EditableTable';
import FormFieldDisplay from './FormFieldDisplay';

const EditableContext = React.createContext(null);
const EditableField = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    renderType,
    displayType,
    sortOrder,
    editType,
    enableShow,
    enableSubForm,
    inputType,
    editFieldName,
    optionsLink,
    required,
    requiredMessage,
    showLabel,
    subFormLink,
    subFormName,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const [form]  = Form.useForm();
    const formField = {
        editType: editType,
        enableShow: enableShow,
        enableSubForm: enableSubForm,
        inputType: inputType,
        label: title,
        name: editFieldName,
        optionsLink: optionsLink,
        required: required,
        requiredMessage: requiredMessage,
        showLabel: showLabel,
        subFormLink: subFormLink,
        subFormName: subFormName,
    }
    useEffect(() => {
        if (editing) {
            try{

                inputRef.current.focus();
            }
        catch{}
            }
    }, [editing]);
    const toggleEdit = () => {
        setEditing(!editing);
        let val=record[editFieldName];
        if(formField.inputType.startsWith('date')){
                val=moment(record[editFieldName])
        }
        form.setFieldsValue({
            
            [editFieldName]: val,
        });
    };
    const cancel = () => {
        toggleEdit();
    }
    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({
                ...record,
                ...values,
            });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };
    
    let childNode = children;
    if(editable){

            childNode = editing ? (
            <div {...restProps}>
                <Form 
                form={form}>
                <FormFieldInput field={formField} inputRef={inputRef} onPressEnter={save} />
                <Form.Item
                    key='buttons'

                >
                    <Button onClick={cancel}>
                        Отмена
                    </Button>
                    <Button type="primary" onClick={save} >
                        Сохранить
                    </Button>
                </Form.Item>
                </Form>
            </div>
        ) : (
            <div onDoubleClick={toggleEdit}>
                <div
                    className="editable-cell-value-wrap"
                    style={{
                        paddingRight: 24,
                    }}

                >
                    <FormFieldDisplay field={formField} fieldValue={record[dataIndex]}/>
                </div>
            </div>
        );
        return(
            childNode
        ) ;
    }
    return(
        <div >
                <div
                    className="editable-cell-value-wrap"
                    style={{
                        paddingRight: 24,
                    }}

                >
                    <FormFieldDisplay field={formField} fieldValue={record[dataIndex]}/>
                </div>
            </div>
    )
};
const DetailsForm = ({linkGet, linkGetColumns,linkUpdate})=>{
    const [dataSource, setDataSource] = useState();
    const [dataColumns, setDataCols] = useState([]);
    const loadOnce = useRef(true);
    useEffect(() => {
        if (loadOnce.current === true) {
            loadOnce.current = false
            fetchDataSource().then(() => {
                setColumns()

            })
        }
    }, []);
    const fetchDataSource = async () => {
        const res = await apiClient.get(linkGet);
        setDataSource(res.data)
    }
 
    const setColumns = async () => {
        const res = await apiClient.get(linkGetColumns)
        setDataCols(res.data);
    }

    const handleSave = async (field) => {
        await apiClient.post(linkUpdate, field)
        fetchDataSource().then(() => {
            setColumns()

        })
    };

    const SipleFields=dataColumns.filter((val) => val.renderType != 'table'&&val.renderType!='details'&&val.renderType !== 'tableValue')

    const TabFields=dataColumns.filter((val) => val.renderType === 'table'||val.renderType==='details'||val.renderType === 'tableValue')

    const descriptionItems=SipleFields.map((field)=>{
        const editProps = {
            record:dataSource,
            dataIndex:field.dataIndex,
            renderType: field.renderType,
            enableShow:field.enableShow,
            displayType: field.displayType,
            editable: field.editable,
            title: field.title,
            sortOrder: field.sortOrder,
            editType: field.editType,
            enableSubForm: field.enableSubForm,
            inputType: field.inputType,
            editFieldName: field.editFieldName,
            optionsLink: field.optionsLink,
            required: field.required,
            requiredMessage: field.requiredMessage,
            showLabel: field.showLabel,
            subFormLink: field.subFormLink,
            subFormName: field.subFormName,
            handleSave,
        }
        return (
            <Descriptions.Item label={field.title}>
                    <EditableField {...editProps}/>
            </Descriptions.Item>
        )
    })

    const tabItems=TabFields.map((field=>{

        const record=dataSource.guidId;
        const item={
        label: field.title,
        key: field.title,
        children: (field.renderType==='details'?
            <DetailsForm 
                linkGet={field.linkGet +dataSource[field.editFieldName]} 
                linkGetColumns={field.linkGetColumns+dataSource[field.editFieldName]} 
                linkUpdate={field.linkUpdate+dataSource[field.editFieldName]}
                />
        :
                <EditableTable
                                enableEdit={field.editable}
                                enableAddButton={false}
                                linkGetColumns={field.linkGetColumns + record}
                                linkGet={field.linkGet + record}
                                linkCreate={field.linkCreate + record}
                                linkDelete={field.linkDelete}
                                linkUpdate={field.linkUpdate + record}
                                linkGetCreationFields={field.linkGetCreationFields + record}
                                enableDelete={field.enableDelete}
                                modalTitle={field.modalTitle}
                            />
            )
        }
        return (item)
    }))

    return (
        <>
            <Descriptions bordered size="small">
                {descriptionItems}
            </Descriptions>
            <Divider/>
            <Tabs  type="card"
                items={tabItems}
            />
        </>
    )
}
export default DetailsForm;