import EditableTable from './EditableTable'

const Flights = () => {
    return (
        <div>
            <EditableTable
                tableTitle='Рейсы'
                enableEdit={true}
                enableAddButton={false}
                enableDateSelector={true}
                linkGetColumns="Flights/GetFlightColumns"
                linkGet="Flights/GetFlights"
                linkCreate='Flights/CreateFlight'
                linkDelete="Flights/DeleteFlight"
                linkUpdate="Flights/UpdateFlight"
                linkGetCreationFields='Flights/GetFlightFormEdits'
                enableDelete={false}
                modalTitle="Добавление Рейса"
            />
        </div>
    );
};

export default Flights;