import React, { useContext, useEffect, useRef, useState } from 'react';
import EditableTable from './EditableTable'

const AircraftTypes = () => {
  return (
    <div>
      <EditableTable
        tableTitle='Типы ВС'
        enableEdit={true}
        enableAddButton={true}
        linkGetColumns="Aircrafts/GetAircraftTypeColumns"
        linkGet="Aircrafts/GetAircraftTypes"
        linkCreate='Aircrafts/CreateAircraftType'
        linkDelete="Aircrafts/DeleteAircraftType"
        linkUpdate="Aircrafts/UpdateAircraftType"
        linkGetCreationFields='Aircrafts/GetAircraftTypeFormEdits'
        enableDelete={true}
        modalTitle="Добавление типа ВС"
      />
    </div>
  );
};

export default AircraftTypes;