import React, { useContext, useEffect, useRef, useState } from 'react';
import EditableTable from './EditableTable'

const Airports = () => {
    return (
        <div>
            <EditableTable
                tableTitle='Аэропорты'
                enableEdit={true}
                enableAddButton={true}
                linkGetColumns="Airports/GetAirportColumns"
                linkGet="Airports/GetAirports"
                linkCreate='Airports/CreateAirport'
                linkDelete="Airports/DeleteAirport"
                linkUpdate="Airports/UpdateAirport"
                linkGetCreationFields='Airports/GetAirportFormEdits'
                enableDelete={true}
                modalTitle="Добавление Аэропорта"
            />
        </div>
    );
};

export default Airports;