import { Outlet } from "react-router-dom";
import { Layout, Menu, theme } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import AppRoutes from '../AppRoutes';

const { Sider, Content } = Layout;
const LayoutOutlet = () => {
    const navigate = useNavigate();
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    return (
        <main className="App">
            <Layout style={{
                minHeight: '100vh',
            }}>
                <Sider>
                    {/* <div
                    style={{
                        height: 32,
                        margin: 16,
                        background: 'rgba(255, 255, 255, 0.2)',
                    }}
                /> */}
                    <Menu
                        onClick={({ key }) => {
                            navigate(key);
                        }
                        }
                        theme="dark"
                        mode="inline"
                        defaultSelectedKeys={[window.location.pathname]}
                        items={AppRoutes.map((route, index) => {
                            return {
                                key: route.path,
                                label: route.label
                            };
                        })}
                    />
                </Sider>
                <Layout className="site-layout">

                    <Content
                        style={{
                            padding: 24,
                            minHeight: 280,
                            background: colorBgContainer,
                        }}
                    >

                        <Outlet />
                    </Content>
                </Layout>

            </Layout>
        </main>
    )
}

export default LayoutOutlet;