import AuthContext from './AuthProvider';
import { useContext } from 'react';
import { Navigate } from "react-router-dom";

const Logout = () => {
    const { setAuth } = useContext(AuthContext);
    
    setAuth({});
    return (
        <Navigate to='/'/>
    )
}
export default Logout;