import AppUsers from './components/AppUsers'
import Aircrafts from './components/Aircrafts'
import AircraftTypes from './components/AircraftTypes'
import Pilots from './components/Pilots'
import FlightSquads from './components/FlightSquads'
import Airports from './components/Airports'
import Flights from './components/Flights'
import Logout from './components/Logout'

const AppRoutes = [
    // {
    //     path: "/",
    //     label:"Отчеты",
    //     element:  <div><h1 id="tabelLabel" >Раздел в разработке</h1></div >
    // },
    {
        path: "/AppUsers",
        label:"Пользователи",
        element: <AppUsers/>
    },
    {
        path: "/Pilots",
        label:"Пилоты",
        element: <Pilots/>
    },
    {
        path: "/FlightSquads",
        label:"Летные отряды",
        element: <FlightSquads/>
    },
    {
        path: "/Aircrafts",
        label:"ВС",
        element: <Aircrafts/>
    },
    {
        path: "/AircraftTypes",
        label:"Типы ВС",
        element: <AircraftTypes/>
    },
    {
        path: "/Airports",
        label:"Аэропорты",
        element: <Airports/>
    }
    ,
    {
        path: "/",
        label:"Рейсы",
        element: <Flights/>
    },
    {
        path: "/Logout",
        label:"Выход из системы",
        element: <Logout/>
    }
    
];

export default AppRoutes;