import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Form, Popconfirm, Table, Modal, Tag, Space, Layout, Drawer, Switch, DatePicker, Spin } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import CreationForm from './CreationForm';
import apiClient from '../api/apiClient'
import moment from 'moment'
import FormFieldInput from './FormFieldInput';
import DetailsForm from './DetailsForm';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
const { Content } = Layout;
const dateFormat="DD.MM.YYYY"
const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};
const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    renderType,
    displayType,
    sortOrder,
    editType,
    enableShow,
    enableSubForm,
    inputType,
    editFieldName,
    optionsLink,
    required,
    requiredMessage,
    showLabel,
    subFormLink,
    subFormName,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    const formField = {
        editType: editType,
        enableShow: enableShow,
        enableSubForm: enableSubForm,
        inputType: inputType,
        label: title,
        name: editFieldName,
        optionsLink: optionsLink,
        required: required,
        requiredMessage: requiredMessage,
        showLabel: showLabel,
        subFormLink: subFormLink,
        subFormName: subFormName,
    }
    useEffect(() => {
        if (editing) {
            try {
                inputRef.current.focus();

            }
            catch { }
        }
    }, [editing]);
    const toggleEdit = () => {
        setEditing(!editing);
        let val = record[editFieldName];
        if (formField.inputType.startsWith('date')) {
            val = moment(record[editFieldName])
        }
        form.setFieldsValue({

            [editFieldName]: val,
        });
    };
    const cancel = () => {
        toggleEdit();
    }
    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({
                ...record,
                ...values,
            });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };
    let childNode = children;
    if (editable) {
        childNode = editing ? (
            <td {...restProps}>
                <FormFieldInput field={formField} inputRef={inputRef} onPressEnter={save} />
                <Form.Item
                    key='buttons'

                >
                    <Button onClick={cancel}>
                        Отмена
                    </Button>
                    <Button type="primary" onClick={save} >
                        Сохранить
                    </Button>
                </Form.Item>
            </td>
        ) : (
            <td onDoubleClick={toggleEdit} {...restProps}>
                <div
                    className="editable-cell-value-wrap"
                    style={{
                        paddingRight: 24,
                    }}

                >
                    {children}
                </div>
            </td>
        );
        return childNode;
    }
    return <td {...restProps}>{childNode}</td>;
};
const EditableTable = ({ tableTitle, enableEdit, enableAddButton, enableDateSelector, linkGetColumns, linkGet, linkCreate, linkDelete, linkUpdate, linkGetCreationFields, enableDelete, modalTitle }) => {
    const [dataSource, setDataSource] = useState([]);
    const [datePeriod, setDatePeriod] = useState([moment().add(-2,'d').format(dateFormat),moment().add(1,'d').format(dateFormat)]);
    const [form] = Form.useForm();
    const [dataColumns, setDataCols] = useState([]);
    const enableReloadColumns = useRef(true);
    const loadOnce = useRef(true);

    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const showModal = () => {
        setOpen(true);
    };

    const handleOk = () => {
        setLoading(true);
        fetchDataSource();
        setTimeout(() => {
            setLoading(false);
            setOpen(false);
        }, 1000);
    };
    const handleCancel = () => {
        setOpen(false);
    };

    const fetchDataSource = async () => {
        if(dataColumns.length>0){
        setLoading(true)

        let data={}
        if (enableDateSelector) {
            let link=`${linkGet}${linkGet.includes('?')?'&&':'?'}dateBegin=${moment(datePeriod[0],dateFormat).format('YYYY-MM-DD')}&&dateEnd=${moment(datePeriod[1],dateFormat).format('YYYY-MM-DD')}`;
            const res = await apiClient.get(link);
            data=res.data
        }
        else{
            const res = await apiClient.get(linkGet);
            data=res.data
        }
        setDataSource(data)
    }
    }

    const handleDelete = async (guid) => {
        apiClient.post(linkDelete, null, { headers: { 'Content-Type': 'multipart/form-data' }, params: { guidId: guid } }).then(() => {
            fetchDataSource()
        })
    };

    const handleSave = (row) => {
        const newData = [...dataSource];
        const index = newData.findIndex((item) => row.guidId === item.guidId);
        newData.splice(index, 1);
        setDataSource(newData)
        apiClient.post(linkUpdate, row).then(() => {
            if (enableReloadColumns.current) {
                setColumns()
            }
            else {
                fetchDataSource()
            }
        })
    };
    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };
   
    const datePickerChanged =async (dates,dateStrings)=>{
        if(dateStrings.length===2&&dateStrings[0].length>0&&dateStrings[1].length>0){
            setDatePeriod(dateStrings)
        }
    }
    useEffect(() => {
        if (loadOnce.current === true) {
            loadOnce.current = false
            setColumns()
        }
    });
    useEffect(() => {
            fetchDataSource()
    }, [dataColumns,datePeriod]);// eslint-disable-line react-hooks/exhaustive-deps
                
   useEffect(()=>{
    setLoading(false)
   },[dataSource])

   const setColumns = async () => {
        setLoading(true)
        const res = await apiClient.get(linkGetColumns)
        setDataCols([])
        const cols = res.data.filter((row) => row.enableShow !== false).map((row, index) => {
            let additionalFields = {};
            switch (row.renderType) { // eslint-disable-line default-case
                case 'tags': {
                    additionalFields.render = (tags) => (
                        <span>
                            {tags.map((tag) => {
                                return (
                                    <Tag key={tag}>
                                        {tag}
                                    </Tag>
                                );
                            })}
                        </span>
                    )
                    break;
                }
                case 'date': {
                    additionalFields.render = (record) => {
                        return (
                            moment(record).format("DD.MM.YYYY")
                        );
                    }
                    break;
                }
                case 'dateTime': {
                    additionalFields.render = (record) => {
                        return (
                            moment(record).format("DD.MM.YYYY HH:mm")
                        );
                    }
                    break;
                }
                case 'time': {
                    additionalFields.render = (record) => {
                        return (
                            moment(record).format("HH:mm")
                        );
                    }
                    break;
                }
                case 'table': {
                    additionalFields.render = (record) => {
                        return ({
                            props: {
                                style: {
                                    padding: "0px",
                                },
                            },
                            children:
                                <EditableTable
                                    enableEdit={row.editable}
                                    enableAddButton={row.enableAddButton}
                                    linkGetColumns={row.linkGetColumns + record}
                                    linkGet={row.linkGet + record}
                                    linkCreate={row.linkCreate + record}
                                    linkDelete={row.linkDelete}
                                    linkUpdate={row.linkUpdate + record}
                                    linkGetCreationFields={row.linkGetCreationFields + record}
                                    enableDelete={row.enableDelete}
                                    modalTitle={row.modalTitle}
                                />
                        }
                        );
                    }
                    break;
                }
                case 'drawer': {
                    additionalFields.render = (text, record) => {
                        let params = {
                            enableEdit: row.editable,
                            enableAddButton: row.enableAddButton,
                            linkGetColumns: row.linkGetColumns + record[row.linkId],
                            linkGet: row.linkGet + record[row.linkId],
                            linkCreate: row.linkCreate + record[row.linkId],
                            linkDelete: row.linkDelete,
                            linkUpdate: row.linkUpdate + record[row.linkId],
                            linkGetCreationFields: row.linkGetCreationFields + record[row.linkId],
                            enableDelete: row.enableDelete,
                            modalTitle: row.modalTitle,
                        }
                        return (
                            <>
                                <DrawerForm textValue={text} title={row.title + ' ' + text} placement={row.placement} func="EditableTable" params={params} />
                            </>
                        );
                    }
                    break;
                }
                case 'bool': {
                    additionalFields.render = (text) => {
                        return (
                            <Switch disabled={true} defaultChecked={text} />
                        )
                    }
                    break;
                }
                case 'mainDetails': {
                    additionalFields.render = (text, record) => {
                        let params = {
                            enableEdit: row.editable,
                            enableAddButton: row.enableAddButton,
                            linkGetColumns: row.linkGetColumns + record[row.linkId],
                            linkGet: row.linkGet + record[row.linkId],
                            linkCreate: row.linkCreate + record[row.linkId],
                            linkDelete: row.linkDelete,
                            linkUpdate: row.linkUpdate + record[row.linkId],
                            linkGetCreationFields: row.linkGetCreationFields + record[row.linkId],
                            enableDelete: row.enableDelete,
                            modalTitle: row.modalTitle,
                        }
                        return (
                            <FormDetailModal textValue={text} title={row.title + ' ' + text} params={params} handleClose={fetchDataSource} />
                        );
                    }
                    break;
                }
            }
            if (enableEdit) {
                if (row.editable === true && row.renderType !== 'table' && row.renderType !== 'tableValue' && row.renderType !== 'drawer' && row.renderType !== 'mainDetails' && row.renderType !== 'details') {
                    additionalFields.onCell = (record) => ({
                        record,
                        renderType: row.renderType,
                        displayType: row.displayType,
                        editable: row.editable,
                        title: row.title,
                        sortOrder: row.sortOrder,
                        editType: row.editType,
                        enableShow: row.enableShow,
                        enableSubForm: row.enableSubForm,
                        inputType: row.inputType,
                        editFieldName: row.editFieldName,
                        optionsLink: row.optionsLink,
                        required: row.required,
                        requiredMessage: row.requiredMessage,
                        showLabel: row.showLabel,
                        subFormLink: row.subFormLink,
                        subFormName: row.subFormName,
                        handleSave,
                    })
                }
            }
            //additionalFields.onFilter = (value, record) => record[rowindex].includes(value)
            return {
                title: row.title,
                key: row.dataIndex,
                dataIndex: row.dataIndex,
                editable: row.editable,
                ...additionalFields,
                // sorter: (a, b) => a[row.dataIndex].length - b[row.dataIndex].length,
            }
        });

        if (enableDelete === true) {
            cols.push({
                title: '',
                dataIndex: 'operation',
                render: (_, record) =>

                    <Popconfirm title="Хотите удалить?" onConfirm={() => handleDelete(record.guidId)}>
                        <a href="#">Удалить</a>{/* eslint-disable-line jsx-a11y/anchor-is-valid */}
                         
                        
                    </Popconfirm>
            })
        };
        setDataCols(cols);


    }
    const onChange = (pagination, filters, sorter, extra) => {
        loadOnce.current = true;
    };

    const FormModal = () => {
        return (
            <Modal open={open} title={modalTitle} onOk={handleOk} onCancel={handleCancel} footer={[]}>
                <CreationForm onOk={handleOk} linkToPost={linkCreate} onCancel={handleCancel} linkGetFields={linkGetCreationFields} />
            </Modal>
        );
    }


    return (
<Spin spinning={loading}>
        <Content >

            {tableTitle || enableAddButton || enableDateSelector ?
                <Form form={form}
                    name="basic"
                    autoComplete="off"
                    initialValues={{
                        Picker:[dayjs(datePeriod[0], dateFormat), dayjs(datePeriod[1], dateFormat)]
                    }
                    }
                >
                    {enableAddButton ?
                        <Form.Item style={{ marginBottom: 2, }}>
                            <Button onClick={showModal} type="primary">
                                Добавить запись
                            </Button>

                        </Form.Item>
                        : null}
                    {enableDateSelector ?

                        <Form.Item label="Период" name="Picker"
                        style={{ marginBottom: 2, }}
                        >
                            <DatePicker.RangePicker
                           
                           onChange={datePickerChanged}
                           format={dateFormat}
                            />
                        </Form.Item>
                        : null
                    }

                </Form>
                : null
            }
             
            <Table key={linkGet}
                locale={{
                    triggerDesc: 'Сортировать по убыванию',
                    triggerAsc: 'Сортировать по возрастанию',
                    cancelSort: 'Отменить сортировку'
                }}
                pagination={false}
                components={components}
                rowClassName={() => 'editable-row'} onChange={onChange} bordered dataSource={dataSource} columns={dataColumns} rowKey={record => record.guidId} size="small" />
                
            <FormModal />
        </Content>
        </Spin>
    );
};

export default EditableTable;

const DrawerForm = ({ textValue, title, placement, func, params }) => {
    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    return (
        <>
            <Space>
                {textValue}
                {placement === 'bottom' ?
                    <DownOutlined onClick={showDrawer} />
                    : <RightOutlined onClick={showDrawer} />
                }
            </Space>
            <Drawer
                title={title}
                placement={placement} onClose={onClose} open={open}>
                {eval(func)(params)} {/* eslint-disable-line  no-eval */}

            </Drawer>
        </>
    );
}

const FormDetailModal = ({ textValue, title, params, handleClose }) => {
    const [formModalOpen, setFormModalOpen] = useState(false);
    const showModal = () => {
        setFormModalOpen(true);
    };
    const onClose = () => {
        setFormModalOpen(false);
        try {
            handleClose();
        }
        catch { }
    };


    return (
        <>
            <Space>
                {textValue}
                <DownOutlined onClick={showModal} />
            </Space>
            <Modal open={formModalOpen} title={title} width={1500} height={1000} onOk={onClose} onCancel={onClose}>
                <DetailsForm linkGet={params.linkGet} linkGetColumns={params.linkGetColumns} linkUpdate={params.linkUpdate} />
            </Modal>
        </>

    );
}