import React, { useContext, useEffect, useRef, useState } from 'react';
import EditableTable from './EditableTable'

const AppUsers = () => {
  return (
    <div>
      <EditableTable
        tableTitle='Пользователи'
        enableEdit={true}
        enableAddButton={true}
        linkGetColumns="Users/GetColumns"
        linkGet="Users/GetUsers"
        linkCreate='Users/CreateUser'
        linkDelete="Users/DeleteUser"
        linkUpdate="Users/UpdateUser"
        linkGetCreationFields='Users/GetFormEdits'
        enableDelete={true}
        modalTitle="Добавление пользователя"
      />
    </div>
  );
};

export default AppUsers;