import { Button, Form, Input, Layout, theme, notification, Spin } from 'antd';
import { useLocation, Navigate, useNavigate } from "react-router-dom";
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import AuthContext from './AuthProvider';
import { useContext,useState } from 'react';
import apiClient from '../api/apiClient';
const { Sider,  Content } = Layout;

const authUrl = 'users/validateuser'
const Login = () => {
  const [loading, setLoading] = useState(false);
  const { auth, setAuth } = useContext(AuthContext);
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement) => {
    api.error({
      message: `Ошибка авторизации`,
      description:
        'Неправильный логин или пароль',
      placement,
    });
  };
  const onFinish = async (values) => {
    try {
      setLoading(true)
      const res = await apiClient.post(authUrl, values,);
      const userToken = res?.data?.userToken;
      const userName = res?.data?.fio;
      let path = location.state.from.pathname;
      if (path.toLowerCase() === '/logout') {
        path = '/'
      }
      setAuth({ userName, userToken })
      navigate(path)
    }
    catch {
      openNotification('top')
      form.resetFields();
      setLoading(false)

    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  return (
    auth?.userToken
      ? <Navigate to='/' />
      :
      <main className="App">
        <Spin spinning={loading} delay={500}>
          <Layout style={{
            minHeight: '100vh',
          }}>
            {contextHolder}
            <Sider>

            </Sider>
            <Layout className="site-layout">

              <Content
                style={{
                  padding: 24,
                  minHeight: 280,
                  background: colorBgContainer,
                }}
              >
                <Form form={form}
                  name="basic"
                  labelCol={{
                    offset: 8,
                    span: 8,
                  }}
                  wrapperCol={{
                    offset: 8,
                    span: 16,
                  }}
                  style={{
                    maxWidth: 600,
                  }}
                 
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Form.Item
                   wrapperCol={{
                    offset: 8,
                    span: 16,
                  }}
                    name="login"
                    rules={[
                      {
                        required: true,
                        message: 'Введите логин!',
                      },
                    ]}
                  >
                    <Input  prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Логин" />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Введите пароль!',
                      },
                    ]}
                  >
                    <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          placeholder="Пароль"
        />
                  </Form.Item>
                  <Form.Item
                    wrapperCol={{
                      offset: 8,
                      span: 16,
                    }}
                  >
                    <Button type="primary" htmlType="submit">
                      Войти
                    </Button>
                  </Form.Item>

                </Form>
              </Content>
            </Layout>

          </Layout>
        </Spin>
      </main>
  )
};
export default Login;