import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useRef, useState } from 'react'
import { Form, Select, Button, Input, DatePicker, TimePicker, InputNumber, Space,Switch } from 'antd';
import apiClient from '../api/apiClient'

const { Option } = Select;

const FormFieldInput = ({ field,inputRef,onPressEnter,onBlur}) => {
    const loadOnce = useRef(true);
    const selectChanged = useRef(false);
    const [selectOptions, SetSelectOptions] = useState([]);

    const SetOptions = async () => {
        const res = await apiClient.get(field.optionsLink)
        const cols = res.data.map((row) => {
            return row;
        });
        SetSelectOptions(cols);
    };
    const selectChange = () => {
        selectChanged.current = true
    }
    useEffect(() => {
        if (loadOnce.current === true) {
            loadOnce.current = false
            if (field.optionsLink && field.optionsLink.lenght !== 0) {
                SetOptions()
            }
        }
    })

    const Options = selectOptions.map((row) => {
        return (
            <Option key={row.guidId} value={row.guidId}>{row.name}</Option>
        )
    })
    switch (field.inputType) {
        case 'string': {
            return (
                <Form.Item
                    key={field.name}
                    label={field.showLabel?field.label:''}
                    name={field.name}
                    hidden={field.enableShow ? false : true}
                    rules={[
                        {
                            required: field.required,
                            message: field.requiredMessage,
                        }
                    ]}
                >
                    <Input ref={inputRef} placeholder={field.placeholder}  />
                </Form.Item>
            )
        }
        case 'password': {
            return (
                <Form.Item
                    key={field.name}
                    label={field.showLabel?field.label:''}
                    name={field.name}
                    hidden={field.enableShow ? false : true}
                    rules={[
                        {
                            required: field.required,
                            message: field.requiredMessage,

                        },
                        {
                            type: 'string',
                            min: 6,
                            message: 'Пароль должен быть не менее 6 символов'
                        }
                    ]}
                >
                    <Input.Password ref={inputRef} placeholder={field.placeholder} onPressEnter={onPressEnter} onBlur={onBlur} />
                </Form.Item>
            )
        }
        case 'email': {
            return (
                <Form.Item
                    key={field.name}
                    label={field.showLabel?field.label:''}
                    name={field.name}
                    hidden={field.enableShow ? false : true}
                    rules={[
                        {
                            type: 'email',
                            message: 'Введите правильный E-mail',
                        },
                        {

                            required: field.required,
                            message: field.requiredMessage,
                        }
                    ]}
                >
                    <Input ref={inputRef} placeholder={field.placeholder} onPressEnter={onPressEnter} onBlur={onBlur} />
                </Form.Item>
            )
        }
        case 'multiline': {
            return (
                <Form.Item
                    key={field.name}
                    label={field.showLabel?field.label:''}
                    name={field.name}
                    hidden={field.enableShow ? false : true}
                    rules={[
                        {
                            required: field.required,
                            message: field.requiredMessage,
                        }
                    ]}
                >
                    <Input.TextArea allowClear ref={inputRef} placeholder={field.placeholder} onPressEnter={onPressEnter} onBlur={onBlur} />
                </Form.Item>
            )
        }
        case 'int': {
            return (
                <Form.Item
                    key={field.name}
                    label={field.showLabel?field.label:''}
                    name={field.name}
                    hidden={field.enableShow ? false : true}
                    rules={[
                        {
                            required: field.required,
                            message: field.requiredMessage,
                        }
                    ]}
                >
                    <InputNumber ref={inputRef} step={field.step} placeholder={field.placeholder} onPressEnter={onPressEnter} onBlur={onBlur} />
                </Form.Item>
            )
        }
        case 'date': {
            return (
                <Form.Item
                    key={field.name}
                    label={field.showLabel?field.label:''}
                    name={field.name}
                    hidden={field.enableShow ? false : true}
                    rules={[
                        {
                            required: field.required,
                            message: field.requiredMessage,
                        }
                    ]}
                >
                    <DatePicker /*format="DD.MM.YYYY"*/ ref={inputRef} placeholder={field.placeholder} onPressEnter={onPressEnter} onBlur={onBlur} />
                </Form.Item>
            )
        }
        case 'time': {
            return (
                <Form.Item
                    key={field.name}
                    label={field.showLabel?field.label:''}
                    name={field.name}
                    hidden={field.enableShow ? false : true}
                    rules={[
                        {
                            required: field.required,
                            message: field.requiredMessage,
                        }
                    ]}
                >
                    <TimePicker ref={inputRef} placeholder={field.placeholder} onPressEnter={onPressEnter} onBlur={onBlur} />
                </Form.Item>
            )
        }
        case 'dateTime': {
            return (
                <Form.Item
                    key={field.name}
                    label={field.showLabel?field.label:''}
                    name={field.name}
                    hidden={field.enableShow ? false : true}
                    rules={[
                        {
                            required: field.required,
                            message: field.requiredMessage,
                        }
                    ]}
                >
                    <DatePicker showTime /*format="DD.MM.YYYY HH:mm:ss" */ ref={inputRef} placeholder={field.placeholder} onPressEnter={onPressEnter} onBlur={onBlur} />
                </Form.Item>
            )
        }
        case 'dropdown': {
            return (
                <>
                    <Form.Item
                        key={field.name}
                        label={field.showLabel?field.label:''}
                        name={field.name}
                        hidden={field.enableShow ? false : true}
                        rules={[
                            {
                                required: field.required,
                                message: field.requiredMessage,
                            }
                        ]}
                    >
                        <Select onChange={selectChange} ref={inputRef} placeholder={field.placeholder} onPressEnter={onPressEnter} onBlur={onBlur}>
                            {Options}
                        </Select>
                    </Form.Item>

                    {field.enableSubForm ?
                    <Form.Item
                        hidden={field.enableSubForm ? false : true}
                        noStyle
                        shouldUpdate={() => selectChanged.current}>
                        {({ getFieldValue }) =>
                            getFieldValue(field.name) ? (
                                <SubForm SubFormName={field.subFormName} GetFieldsLink={field.subFormLink} FieldValue={getFieldValue(field.name)} SelectChanged={selectChanged} />
                            ) : null
                        }
                    </Form.Item>
                    :null
        }
                </>
            )
        }
        case 'dropdownMultiple': {
            return (
                <>
                    <Form.Item
                        key={field.name}
                        label={field.showLabel?field.label:''}
                        name={field.name}
                        hidden={field.enableShow ? false : true}
                        rules={[
                            {
                                required: field.required,
                                message: field.requiredMessage,
                            }
                        ]}
                    >
                        <Select mode="multiple" ref={inputRef} placeholder={field.placeholder} onPressEnter={onPressEnter} onBlur={onBlur}>
                            {Options}
                        </Select>
                    </Form.Item>
                </>
            )
        }
        case 'multipleFields': {
            return (
                <>
                    <Form.Item
                        key={field.name}
                        label={field.showLabel?field.label:''}
                        hidden={field.enableShow ? false : true}
                    >
                        <Form.List name={field.name}>
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                            <Form.Item
                                                {...restField}
                                                name={[name]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: field.requiredMessage,
                                                    },
                                                ]}
                                            >
                                                <Input ref={inputRef} placeholder={field.placeholder} onPressEnter={onPressEnter} onBlur={onBlur} />
                                            </Form.Item>
                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Добавить {field.label}
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Form.Item>
                </>
            )
        }
        case 'bool': {
            return (
                <Form.Item
                    key={field.name}
                    label={field.showLabel?field.label:''}
                    name={field.name}
                    hidden={field.enableShow ? false : true}
                    rules={[
                        {
                            required: field.required,
                            message: field.requiredMessage,
                        }
                    ]}
                >
                    <Switch defaultChecked />
                </Form.Item>
            )
        }
        default: {
            return (
                <Form.Item
                    key={field.name}
                    label={field.showLabel?field.label:''}
                    name={field.name}
                    hidden={field.enableShow ? false : true}
                    rules={[
                        {
                            required: field.required,
                            message: field.requiredMessage,
                        }
                    ]}
                >
                    <Input />
                </Form.Item>
            )
        }
    }


}
const SubForm = ({ SubFormName, GetFieldsLink, FieldValue, SelectChanged }) => {
    const [formFields, SetFormFields] = useState([]);
    const SetFields = async () => {
        const res = await apiClient.get(GetFieldsLink + FieldValue)
        const cols = res.data.map((row) => {
            return row;
        });

        SetFormFields(cols);

    };
    if (SelectChanged.current === true) {
        SetFields()
        SelectChanged.current = false
    }
    const FormItems = formFields.map((field) => {
        return (
            <FormFieldInput field={field} />
        )
    })
    return (

        <Form.List key={FieldValue} name={SubFormName}>

            {(fields, { add, remove }) => (
                <>
                    {FormItems}
                </>
            )}

        </Form.List>

    )
}


export default FormFieldInput;