import axios from "axios";
import useAuth from "../components/useAuth";
import { createContext,useState,useEffect } from "react";
const getLocalStorage=(key)=> {
    try {
      const value = window.localStorage.getItem(key);
      return value ? JSON.parse(value) : {};
    } catch (e) {
      return {};
    }
  }
const GetToken = ()=>{
    const auth=getLocalStorage("auth");
try{
    
    return  auth?.userToken
}
catch {
    return "";
}
}
const baseLink=process.env.REACT_APP_API_URL;
const apiClient=axios.create({
    baseURL:baseLink
});
apiClient.interceptors.request.use( (config) =>{
    // Do something before request is sent
    const userToken=GetToken();
    config.headers["Authorization"] = "Bearer " + userToken;
    return config;
});
export default apiClient