import { Layout, Menu, theme } from 'antd';
import React, { useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import LayoutOutlet from './components/Layout';
import RequireAuth from './components/RequireAuth';
import Login from './components/Login';
const { Header, Sider, Footer, Content } = Layout;

const App = () => {
    const navigate = useNavigate();
    const [collapsed, setCollapsed] = useState(false);
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return (
        <Routes>
            <Route path='/login' element={<Login />} />
            <Route path='/' element={<LayoutOutlet />}>
                <Route element={<RequireAuth />}>
                    {AppRoutes.map((route, index) => {
                        const { element, path, ...rest } = route;
                        return <Route path={path} element={element} />;
                    })}
                </Route>
            </Route>
        </Routes>
    );
};
export default App;

