import React from 'react';
import EditableTable from './EditableTable'

const Pilots = () => {
  return (
    <div>
      <EditableTable
        tableTitle='Пользователи'
        enableEdit={true}
        enableAddButton={true}
        linkGetColumns="Pilots/GetColumns"
        linkGet="Pilots/GetPilots"
        linkCreate='Pilots/CreatePilot'
        linkDelete="Pilots/DeletePilot"
        linkUpdate="Pilots/UpdatePilot"
        linkGetCreationFields='Pilots/GetFormEdits'
        enableDelete={true}
        modalTitle="Добавление пользователя"
      />
    </div>
  );
};

export default Pilots;